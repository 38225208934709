import { useAppContext } from "../libs/contextLib";
import Videoslider from "../components/videoslider/Videoslider";
import BlockCTA from "../components/blockCTA/BlockCTA";
import Quote from "../components/quote/Quote";
import ThumbnailCTA from "../components/thumbnailCTA/ThumbnailCTA";
import Klopp from "../components/images/Klopp.png";
import SampleThumb from "../components/images/sample-thumb.png";
import "./Home.css";
import { useMembershipDetails } from "hooks/useMembershipDetails";

function Home() {
  const { contentData } = useAppContext();

  const { hasActiveMembership } = useMembershipDetails();

  const videoSliders = () => {
    if (contentData) {
      return Object.keys(contentData).map((category) => (
        <Videoslider
          key={category}
          title={category
            .split("-")
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(" ")}
          items={contentData[category]}
        >
          {category}
        </Videoslider>
      ));
    }
    return <Videoslider isLoading={true} />;
  };

  const callToAction = () => {
    if (!hasActiveMembership) {
      return (
        <div>
          <ThumbnailCTA thumbnailSrc={SampleThumb} />
          <Quote
            quote="“The eAcademy gives you a unique insight into the skills we value the most at LFC”"
            author="Jürgen Klopp, Manager"
            signatureSrc={Klopp}
            signatureAlt="Jürgen Klopp's handwritten signature"
          />
          <BlockCTA href="https://www.liverpoolfc.com/international-academy/international-courses"></BlockCTA>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="Home">
      {callToAction()}
      {videoSliders()}
    </div>
  );
}

export default Home;
